// *******************************相关输入框校验*********************************88
// 只能输入字母和数字
const letterNumber = (value) => {
  const patter = new RegExp("^/[\\W]/g$");
  if (!patter.test(value)) {
    return false;
  } else {
    callback(); // 必须有此项回调，否则验证会一直不通过
  }
};
// 手机号码验证
const validatePhone = (rule, value, callback) => {
  const patter = new RegExp(
    "^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$"
  );
  if (!patter.test(value)) {
    return callback(new Error("请输入正确格式的手机号！"));
  } else {
    callback(); // 必须有此项回调，否则验证会一直不通过
  }
};
// 邮箱的正则
const validateEmail = (rule, value, callback) => {
  const mailbox = new RegExp(
    "^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*.[a-zA-Z0-9]{2,6}$"
  );
  if (!mailbox.test(value)) {
    return callback(new Error("请输入正确格式的邮箱！"));
  } else {
    callback();
  }
};
// 身份证号
const validateIdCardNo = (rule, value, callback) => {
  const mailbox = new RegExp(
    "^[1-9]d{5}(18|19|([23]d))d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)d{3}[0-9Xx]$)|(^[1-9]d{5}d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)d{2}[0-9Xx]$"
  );
  if (!mailbox.test(value)) {
    return callback(new Error("请输入正确格式的身份证号！"));
  } else {
    callback();
  }
};
export default {
  letterNumber,
  common: {
    // ---------------------公共验证方法
    phone: [
      {
        // 手机号
        required: true,
        message: "手机号不能为空",
      },
      {
        validator: validatePhone,
        trigger: "blur",
      },
    ],
    email: [
      {
        // 邮箱
        required: true,
        message: "邮箱不能为空",
      },
      {
        validator: validateEmail,
        trigger: "blur",
      },
    ],
    idCardNo: [
      {
        // 身份证号码
        required: true,
        message: "身份证号码不能为空",
      },
      {
        validator: validateIdCardNo,
        trigger: "blur",
      },
    ],
  },
  handle: {
    // ---------------------处理表单的验证方法
    ProjectCode: [
      {
        //工程编号
        required: true,
        message: "请选择工程编号",
      },
    ],
    ShopCode: [
      {
        //商铺编号
        required: true,
        message: "请输入商铺编号",
      },
    ],
    InvestmentCode: [
      {
        //商铺编号
        required: true,
        message: "请输入商铺编号",
      },
    ],
    BuildingId: [
      {
        //楼栋
        required: true,
        message: "请选择楼栋编号",
      },
    ],
    IsOnGround: [
      {
        //地上地下
        required: true,
        message: "请选择地上地下",
      },
    ],
    Storey: [
      {
        //楼层
        required: true,
        message: "请输入楼层",
      },
    ],
    ShopName: [
      {
        //商铺名称
        required: true,
        message: "请输入商铺名称",
      },
    ],
    ShopArea: [
      {
        //商铺面积
        required: true,
        message: "请输入商铺面积",
      },
    ],
    ShopFormatClass: [
      {
        required: true,
        message: "请选择业态级别",
      },
    ],
    ShopFormatName: [
      {
        required: true,
        message: "请选择业态名称",
      },
    ],
    ShopBrand: [
      {
        required: true,
        message: "请输入品牌信息",
      },
    ],
    ShopChangeReason: [
      {
        required: true,
        message: "请输入变更原因",
      },
    ],
  },
};
