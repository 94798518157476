<!--https://blog.csdn.net/qq_43291759/article/details/125079463-->
<!--公共输入框组件-->
<template>
  <div>
    <div v-if="title === '备注'" class="inputBox">
      <p :style="titleStyle" class="inputBoxP">
        {{ title }}
        <slot name="dropdown"></slot>
      </p>
      <el-input
          :style="inputStyle"
          type="textarea"
          :placeholder="placeholder"
          v-bind:value="value"
          maxlength="200"
          :rows="7"
          resize="none"
          :minlength="minlength"
          :show-word-limit="showWordLimit"
          :clearable="clearable"
          :disabled="disabled"
          @input="inputFun"
          @blur="blurFun"
      >
      </el-input>
    </div>

    <div v-else class="inputBox">
      <p :style="titleStyle" class="inputBoxP">
        {{ title }}
        <slot name="dropdown"></slot>
      </p>
      <!--       type
        :autosize="type === 'textarea' ? { minRows: 4, maxRows: 4 } : {}"-->
      <el-input
          :style="inputStyle"
          :show-word-limit="showWordLimit"
          :maxlength="maxlength"
          :minlength="minlength"
          :clearable="clearable"
          :disabled="disabled"
          :type="type"
          class="inputBoxIpt"
          v-bind:value="value"
          :placeholder="placeholder"
          @input="inputFun"
          @blur="blurFun"
      >
        <span slot="suffix"> {{ suffixText }}</span>
      </el-input>
      <span class="isReauireSpan" v-if="isReauired">*</span>
    </div>

    <!-- <span class="isReauireSpan" v-if="isReauired">*</span> -->
  </div>
</template>

<script>
export default {
  model: {
    // 父组件绑定的值
    prop: "value",
    event: "inputFun",
  },
  props: {
    titleStyle: {
      //输入框前面labe的样式
      type: Object,
      require: false,
    },
    // 输入框尾部的文字或图标
    suffixText: {
      type: String,
    },
    // 输入框本身的样式
    inputStyle: {
      type: Object,
      default: () => {
      },
    },
    inputBoxStyle: {
      type: Object,
      default: () => {
      },
    },
    value: {
      //父组件绑定的值
      type: String | Number,
      require: false,
    },
    width: {
      //输入框的宽度
      type: String,
      default: "",
    },
    title: {
      //输入框前面的label文字
      type: String,
      require: false,
      // default: "请传入Titlt"
    },
    placeholder: {
      //输入框placeholder
      type: String,
      require: false,
      default: "",
    },
    // 是否可清空
    clearable: {
      type: Boolean,
      default: true,
    },
    //输入的最大长度
    maxlength: {
      type: Number,
    },
    //输入的最小长度
    minlength: {
      type: Number,
    },
    isReauired: {
      //输入框必选校验
      type: Boolean,
      require: false,
      default: false,
    },
    showWordLimit: {
      //是否显示字数统计
      type: Boolean,
      require: false,
    },
    type: {
      //输入框的类型 数字  textarea。。。
      type: String,
      require: false,
      default: "text",
    },
    disabled: {
      //是否禁用输入框
      type: Boolean,
      require: false,
      default: false,
    },
    // 支持正则校验
    RegExpFlag: {
      type: String,
      require: false,
      default: "",
    },
  },
  methods: {
    // 输入框实时输入事件
    inputFun(e) {
      if (this.RegExpFlag) {
        this.$emit("inputFun", e.replace(eval(this.RegExpFlag), ""));
        return;
      }
      this.$emit("inputFun", e);
    },
    // 输入框失去焦点事件
    blurFun(e) {
      this.$emit("blurFun", e.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
/*覆盖element  输入框hover时候的后面删除图标的样式*/
::v-deep .el-input__suffix {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputBox {
  //margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  //align-items: center;
  //width: var(--boxWidth);
}

.inputBoxP {
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  margin: 0;
  //width: 150px;
}

.inputBoxIpt {
  //width: 200px;
}

::v-deep el-input__suffix {
  border: 3px solid red !important;
}

::v-deep.el-textarea {
  width: 200px !important;
}

::v-deep.el-textarea__inner {
  width: 200px;
}

.isReauireSpan {
  color: #ff7875;
  font-size: 12px;
  margin-top: 8px;
  margin-left: 8px;
}
</style>
