<template>
    <transition name="fade">
        <div class="message" v-if="visible">
            {{ message }}
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Message',
    props: {
        message: {
            type: String,
            required: true
        },
        duration: {
            type: Number,
            default: 1000
        }
    },
    data() {
        return {
            visible: true
        };
    },
    mounted() {
        setTimeout(() => {
            this.visible = false;
        }, this.duration);
    }
};
</script>

<style scoped>
.message {
    min-width: 100px;
    box-sizing: border-box;
    border-radius: 10px;
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    transition: opacity .3s, transform .4s, top .4s;
    overflow: hidden;
    padding: 15px;
    display: flex;
    text-align: center;
    align-items: center;
    z-index: 99999;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}



/* 其他类型的样式定义... */
</style>