import Vue from "vue";
import MessageComponent from "./Message.vue";

let messageInstance;
let messageContainer = document.createElement("div");
messageContainer.id = "message-container";
document.body.appendChild(messageContainer);

function showMessage(options) {
  // if (messageInstance) {
  //   messageInstance.$destroy(); // 如果之前存在消息实例，先销毁它
  // } else {
  // }

  const MessageConstructor = Vue.extend(MessageComponent);
  messageInstance = new MessageConstructor({
    propsData: options,
  }).$mount();

  messageContainer.appendChild(messageInstance.$el);
}

export default {
  install(Vue, options) {
    Vue.prototype.$phoneMessage = function (options) {
      showMessage(options);
    };
  },
};
