import { Loading } from "element-ui";
import { set } from "nprogress";

// 格式化日对象
const getNowDate = () => {
  var date = new Date();
  var sign2 = ":";
  var year = date.getFullYear(); // 年
  var month = date.getMonth() + 1; // 月
  var day = date.getDate(); // 日
  var hour = date.getHours(); // 时
  var minutes = date.getMinutes(); // 分
  var seconds = date.getSeconds(); //秒
  // 给一位数的数据前面加 “0”
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (day >= 0 && day <= 9) {
    day = "0" + day;
  }
  if (hour >= 0 && hour <= 9) {
    hour = "0" + hour;
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = "0" + minutes;
  }
  if (seconds >= 0 && seconds <= 9) {
    seconds = "0" + seconds;
  }
  return (
    year +
    "-" +
    month +
    "-" +
    day +
    " " +
    hour +
    sign2 +
    minutes +
    sign2 +
    seconds
  );
};

// 文件下载

// 图片url字符串处理
// const imgUrlStr = function(url){
//     if(url){
//         let newUrl = url.substr(-10)
//     }
// }

const download = function (url, name = "", suffix = "") {
  let loadingInstance = Loading.service({
    fullscreen: true,
    lock: true,
    text: "下载中...",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  let token = localStorage.getItem("token");
  function createObjectURL(object) {
    return window.URL
      ? window.URL.createObjectURL(object)
      : window.webkitURL.createObjectURL(object);
  }
  const xhr = new XMLHttpRequest();
  const formData = new FormData();
  xhr.open("post", url); //url填写后台的接口地址，如果是post，在formData append参数（参考原文地址）
  xhr.setRequestHeader("Authorization", "Bearer " + token);
  xhr.responseType = "blob";
  xhr.onload = function (e) {
    if (this.status == 200) {
      setTimeout(() => {
        loadingInstance.close();
      });
      if (this.response.type == "application/json") {
        return alert("下载失败，请稍后再试");
      }
      const blob = this.response;
      let filename = name; //这里的名字，可以按后端给的接口固定表单设置一下名字，如（费用单.xlsx,合同.doc等等）
      if (suffix && suffix.length > 1) {
        filename = filename.concat(suffix);
      }
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        var a = document.createElement("a");
        var url = createObjectURL(blob);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    }
  };
  xhr.send(formData);
};

const downloadBlob = function (blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    const body = document.querySelector("body");

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // fix Firefox
    link.style.display = "none";
    body.appendChild(link);

    link.click();
    body.removeChild(link);

    window.URL.revokeObjectURL(link.href);
  }
};

// 数组去重
const arrayDeduplication = function (arr) {
  var newarr = [];
  for (var i = 0; i < arr.length; i++) {
    if (newarr.lastIndexOf(arr[i]) === -1) {
      newarr.push(arr[i]);
    }
  }
  return newarr;
};
const momenyFormat = function (num) {
  num = Number(num);
  if (isNaN(num)) return "0.00";
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
};
export default {
  getNowDate,
  download,
  downloadBlob,
  arrayDeduplication,
  momenyFormat,
};
