// eslint-disable-next-line
import { HomePageLayout, MainPageLayout } from "@/layouts";

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: "/Home",
    component: HomePageLayout,
    redirect: "/Home/Index",
    hidden: true,
    children: [
      {
        path: "/Home/Index",
        name: "Index",
        component: () => import("@/views/Home/Index"),
      },
    ],
  },
  {
    path: "/Main",
    component: MainPageLayout,
    redirect: "/Project/All",
    hidden: true,
    children: [
      {
        path: "/File",
        name: "File",
        component: () => import("@/views/File/File"),
      },
      {
        path: "/Project/:type",
        name: "Project",
        component: () => import("@/views/Project/Project"),
      },
      {
        path: "/Project/Task/:type",
        name: "Project",
        component: () => import("@/views/Project/Task"),
      },
      {
        path: "/Template",
        name: "Template",
        component: () => import("@/views/Project/Template"),
      },
      {
        path: "/ElevatorGO",
        name: "ElevatorGO",
        component: () => import("@/views/Project/ElevatorGO/ElevatorGO"),
      },
      {
        path: "/Tools",
        name: "Tools",
        component: () => import("@/views/Tools/Tools"),
      },
      {
        path: "/ElevatorGO",
        name: "ElevatorGO",
        component: () => import("@/views/Tools/ToolsPage/ElevatorGO"),
      },
      {
        path: "/Mail",
        name: "Mail",
        component: () => import("@/views/Mail/MailTable"),
      },
      {
        path: "/My",
        name: "My",
        component: () => import("@/views/My/My"),
      },
      {
        path: "/statistics",
        name: "statistics",
        component: () => import("@/views/statistics/statistics"),
      },
    ],
  },
  {
    path: "/XunJianGo",
    name: "XunJianGo",
    component: () => import("@/views/MobileApp/XunJianGo.vue"), //andriond移动端app下载
  },
  {
    path: "/XunJianGoIOS",
    name: "XunJianGoIOS",
    component: () => import("@/views/MobileApp/XunJianGoIOS.vue"), //ios移动端app下载
  },
  {
    path: "/Ding",
    name: "Ding",
    component: () => import("@/views/LoginFile/Ding"),
  },
  {
    path: "/Wechat",
    name: "Wechat",
    component: () => import("@/views/LoginFile/Wechat"),
  },
  {
    path: "/Not/invite",
    name: "NoT",
    component: () => import("@/views/NoT/invite"),
  },
  {
    path: "/Not/Article",
    name: "NoT",
    component: () => import("@/views/NoT/Article"),
  },
  {
    path: "/Not/Privacys",
    name: "NoT",
    component: () => import("@/views/NoT/Privacys"),
  },
  {
    path: "/Not/GoArticle",
    name: "NoT",
    component: () => import("@/views/NoT/GoArticle"),
  },
  {
    path: "/Not/GoPrivacys",
    name: "NoT",
    component: () => import("@/views/NoT/GoPrivacys"),
  },
  {
    path: "/404",
    component: () => import("@/views/exception/404"),
  },
  {
    path: "/My/BindingWX",
    component: () => import("@/views/My/BindingWX"),
  },
  {
    path: "/ExternalUserLogin",
    name: "ExternalUserLogin",
    component: () => import("@/views/Project_ExternalUser/ExternalUserLogin"),
  },

  {
    path: "/ExternalUserInfo",
    name: "ExternalUserInfo",
    component: () => import("@/views/Project_ExternalUser/ExternalUserInfo"),
  },
  {
    path: "/Shop_ApprovalInfoList",
    name: "Shop_ApprovalInfoList",
    meta: {
      keepAlive: true,
    },
    component: () =>
      import("@/views/Project_ExternalUser/Shop_ApprovalInfoList"),
  },
  {
    path: "/Shop_ApprovalInfoDetail",
    name: "Shop_ApprovalInfoDetail",
    component: () =>
      import("@/views/Project_ExternalUser/Shop_ApprovalInfoDetail"),
  },
  //外审登录
  {
    path: "/Shop_SubmitApprovalLogin",
    name: "Shop_SubmitApprovalLogin",
    component: () =>
      import("@/views/Project/Shop_Manage/Shop_SubmitApprovalLogin"),
  },
  {
    path: "/Shop_SubmitApprovalList",
    name: "Shop_SubmitApprovalList",
    component: () =>
      import("@/views/Project/Shop_Manage/Shop_SubmitApprovalList"),
  },
  //审核GO登录
  {
    path: "/RG_SubmitApprovalLogin",
    name: "RG_SubmitApprovalLogin",
    component: () => import("@/views/Project/ReviewGO/RG_SubmitApprovalLogin"),
  },
  {
    path: "/RG_SubmitApprovalList",
    name: "RG_SubmitApprovalList",
    component: () => import("@/views/Project/ReviewGO/RG_SubmitApprovalList"),
  },
  //审核GOH5登录
  {
    path: "/RG_UserLogin",
    name: "RG_UserLogin",
    component: () => import("@/views/Project_RG_User/RG_UserLogin"),
  },
  {
    path: "/RG_UserInfo",
    name: "RG_UserInfo",
    component: () => import("@/views/Project_RG_User/RG_UserInfo"),
  },
  {
    path: "/RG_ApprovalInfoList",
    name: "RG_ApprovalInfoList",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/Project_RG_User/RG_ApprovalInfoList"),
  },
  {
    path: "/RG_ApprovalInfoDetail",
    name: "RG_ApprovalInfoDetail",
    component: () => import("@/views/Project_RG_User/RG_ApprovalInfoDetail"),
  },

  //文件分享页面
  {
    path: "/File_SharingInfo",
    name: "File_SharingInfo",
    component: () => import("@/views/Project/File_Manage/File_SharingInfo"),
  },

  {
    path: "/TransferUrl",
    name: "TransferUrl",
    component: () => import("@/views/TransferUrl/TransferUrl"),
  },
];
