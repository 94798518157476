<template>
  <el-container class="container">

    <div style="overflow-y: auto; width: 100%">
      <router-view></router-view>
      <el-dialog title="" :visible.sync="loginAndRegisterShow" :destroy-on-close="true"
        :before-close="loginAndRegisterClose" :show-close="false" width="428px" class="dialogLoginShow">
        <div class="loginAndRegister" v-loading="loading">
          <!-- 登录 -->
          <div v-if="showPrise && NoToken" class="box1">
            <div id="login">
              <el-card class="box-card" style="border-radius: 20px; height: 500px">
                <div style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  ">
                  <h2 style="margin: 0">欢迎登录</h2>
                  <el-button size="mini" type="text" @click="showRegister(false)">注册账号</el-button>
                </div>
                <el-form @submit.native.prevent :model="ruleForm1" :rules="rules1" ref="ruleForm1">
                  <el-form-item prop="Number">
                    <el-input auto-complete="off" placeholder="请输入手机号" prefix-icon="el-icon-mobile-phone"
                      v-model="ruleForm1.Number"></el-input>
                  </el-form-item>
                  <el-form-item prop="Cipher">
                    <el-input type="password" show-password prefix-icon="el-icon-edit" auto-complete="off"
                      placeholder="输入密码" v-model="ruleForm1.Cipher"></el-input>
                  </el-form-item>
                  <el-form-item prop="Login" class="btn1">
                    <el-button size="mini" type="primary" @click="loginForm" native-type="submit" style="
                        width: 95%;
                        margin-left: 2.5%;
                        font-size: 19px;
                        font-weight: 600;
                        border-radius: 20px;
                      ">登录
                    </el-button>
                  </el-form-item>
                  <el-form-item prop="WeiXin">
                    <img @click="showWX" class="imgs2" src="@/assets/微信.png" alt />
                  </el-form-item>
                  <el-form-item prop="TiaoKuanLogin">
                    <el-checkbox class="tiaokuan" style="padding: 0px 0px 4% 0px" v-model="checked">
                      我已阅读并同意
                      <router-link style="color: #d1100b; text-decoration: none" target="_blank" :to="{ path: '/' }">
                        使用条款
                      </router-link>
                    </el-checkbox>
                  </el-form-item>
                </el-form>
              </el-card>
            </div>
          </div>

          <!-- 注册 -->
          <div v-if="!showPrise && NoToken" class="box2">
            <div id="register">
              <el-card class="box-card" style="border-radius: 20px; height: 500px">
                <div style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 30px;
                  ">
                  <h2 style="margin: 0">
                    {{ !unionid ? "账号注册" : "绑定手机号" }}
                  </h2>
                  <el-button size="mini" type="text" @click="showRegister(true)">已有账号?去登录</el-button>
                </div>
                <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm">
                  <el-form-item prop="tel" style="position: relative">
                    <el-input prefix-icon="el-icon-mobile-phone" v-model="ruleForm2.tel" :disabled="!Codedisabled"
                      auto-complete="off" placeholder="请输入手机号"></el-input>
                  </el-form-item>
                  <el-form-item prop="word" v-show="!Codedisabled">
                    <el-input prefix-icon="el-icon-tickets" v-model="ruleForm2.word" auto-complete="off"
                      placeholder="手机验证码" style="margin-top: -10px">
                    </el-input>
                  </el-form-item>
                  <el-form-item v-if="!unionid" prop="pass" v-show="!Codedisabled">
                    <el-input prefix-icon="el-icon-edit" :type="flag ? 'text' : 'password'" v-model="ruleForm2.pass"
                      auto-complete="off" placeholder="输入密码">
                      <i slot="suffix" :class="flag ? 'el-icon-minus' : 'el-icon-view'"
                        style="margin-top: 8px; font-size: 18px" autocomplete="auto" @click="flag = !flag" />
                    </el-input>
                  </el-form-item>
                  <el-form-item v-if="!unionid" prop="checkPass" v-show="!Codedisabled">
                    <el-input prefix-icon="el-icon-edit" :type="resetflag ? 'text' : 'password'"
                      v-model="ruleForm2.checkPass" auto-complete="off" placeholder="确认密码">
                      <i slot="suffix" :class="resetflag ? 'el-icon-minus' : 'el-icon-view'"
                        style="margin-top: 8px; font-size: 18px" autocomplete="auto" @click="resetflag = !resetflag" />
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="TiaoKuanRegister">
                    <el-checkbox v-show="Codedisabled" style="padding: 0px 0px 4% 0px" v-model="checked">我已阅读并同意<a
                        href="www.yoojet.com/#/Article" target="_blank">使用条款</a>
                    </el-checkbox>
                    <el-button size="mini" class="rBtn" type="primary"
                      @click="submitForm(Codedisabled ? '发送验证码' : '注册')" style="width: 100%">{{ Codedisabled ? "发送验证码" :
                        "注册" }}
                    </el-button>
                  </el-form-item>
                </el-form>
              </el-card>
            </div>
          </div>

          <!-- 直接进入 -->
          <div v-if="!NoToken" class="box1">
            <div id="directEntry">
              <el-card class="box-card" style="border-radius: 20px; height: 500px">
                <div style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  ">
                  <h2 style="margin: 0">欢迎进入</h2>
                  <el-button size="mini" type="text" @click="showLogin(false)">注销登录</el-button>
                </div>
                <el-form ref="ruleForm3">
                  <el-form-item prop="avatar">
                    <el-avatar class="avatar" shape="circle" :size="150" fit="fill" :src="op().Icon"></el-avatar>
                  </el-form-item>
                  <el-form-item prop="directLogin" class="btn1">
                    <el-button size="mini" type="primary" @click="DirectLogin" style="
                        width: 95%;
                        margin-left: 2.5%;
                        font-size: 19px;
                        font-weight: 600;
                        border-radius: 20px;
                      ">
                      立即进入
                    </el-button>
                  </el-form-item>
                  <el-form-item prop="TiaoKuanDirect">
                    <el-checkbox class="tiaokuan" style="padding: 0px 0px 4% 0px" v-model="checked">
                      我已阅读并同意
                      <router-link style="color: #d1100b; text-decoration: none" target="_blank" :to="{ path: '/' }">
                        使用条款
                      </router-link>
                    </el-checkbox>
                  </el-form-item>
                </el-form>
              </el-card>
            </div>
          </div>
        </div>
      </el-dialog>
      <el-dialog title="请输入图形验证码" :visible.sync="dialogVisible" :close-on-click-modal="false" width="30%">
        <div style="display: flex; align-items: center">
          <el-input prefix-icon="el-icon-picture-outline " v-model="ruleForm2.smscode" placeholder="验证码"
            style="width: calc(100% - 120px)"></el-input>
          <div style="width: 120px; height: 40px" @click="yzmclick">
            <Yzm @strchange="strchange" :identifyCode="key"></Yzm>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="getCode">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="" :visible.sync="wxVisible" :close-on-click-modal="false" width="30%">
        <div style="display: flex; justify-content: center">
          <wxlogin id="login_container" style="" :appid="appid" :scope="scope" :redirect_uri="getRedirect_uri()"
            state="STATE" href=""></wxlogin>
        </div>
      </el-dialog>
    </div>
  </el-container>
</template>

<script>
import OperatorCache from "@/utils/cache/OperatorCache";
import TokenCache from "@/utils/cache/TokenCache";
import moment from "moment";
import { mapState } from "vuex";
import Yzm from "@/components/list.vue";
import wxlogin from "vue-wxlogin";

let checkLoginPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入11位手机号"));
  } else {
    callback();
  }
};
let checkRegisterPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入11位手机号"));
  } else {
    callback();
  }
};

export default {
  name: "HomePageLayout",
  components: {
    Yzm,
    wxlogin,
  },
  data() {
    // 登录
    const validatecip = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm1.checkPass !== "") {
          this.$refs.ruleForm1.validateField("checkPass");
        }
        callback();
      }
    };

    // 注册
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm2.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      routersData: [
        { name: "下载", path: "/Home/Index#Download" },
        // { name: "", path: "/Home/Index#Product" },
        // { name: "", path: "/Home/Index#Characteristic" },
        // { name: "", path: "/Home/Index#Function" },
        // { name: "", path: "/Home/Index#Serve" },
      ],
      loading: false,
      loginAndRegisterShow: false,
      showPrise: true,
      showRentPrise: true,
      NoToken: true, //无Token
      ruleForm1: {
        Number: "",
        Cipher: "",
      },
      rules1: {
        Number: [
          {
            required: true,
            message: "请输入手机号码",
            validator: checkLoginPhone,
            trigger: "blur",
          },
        ],
        Cipher: [
          {
            validator: validatecip,
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      ruleForm2: {
        smscode: "",
        tel: "",
        word: "",
        pass: "",
        checkPass: "",
      },
      rules2: {
        smscode: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
        ],
        tel: [
          {
            required: true,
            message: "请输入手机号码",
            validator: checkRegisterPhone,
            trigger: "blur",
          },
        ],
        word: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
        pass: [{ required: true, message: "请输入密码", trigger: "blur" }],
        checkPass: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
      },
      dialogVisible: false,
      dialogVisibleDownload: false,
      timer: null,
      oneMinute: 60,
      flag: false, // 密码可视
      resetflag: false, // 密码可视
      checked: true,
      disabled: true,
      Codedisabled: true,
      buttonText: "",
      isDisabled: false,
      key: "",
      url: "",
      wxVisible: false,
      appid: "wxef625270eda5492e",
      scope: "snsapi_login",
      codeData: {},
      unionid: null,
    };
  },
  watch: {},
  computed: {
    ...mapState(["userInfo", "isLogin"]),
    newDay() {
      return moment().format("YYYY");
    },
  },
  mounted() {
    if (TokenCache.getToken()) {
      this.NoToken = false;
      this.$router.replace("/Project/All");
    }
    this.getYzm();
    if (this.$route.query.code) {
      this.loading = true;
      this.$http
        .post(
          "/Base_Manage/Base_VIPUserInfo/GetWxAccessToken?AppType=zytsaas_pc&Code=" +
          this.$route.query.code
        )
        .then((res) => {
          if (res.Success && res.Data.openid) {
            this.openid = res.Data.openid;
            this.unionid = res.Data.unionid;
            this.wxLoginApi(res.Data.openid, res.Data.unionid);
          } else {
            this.$message.error("微信授权获取失败,请重新尝试");
            this.loading = false;
          }
        });
    }
  },
  methods: {
    getTitle() {
      return process.env.VUE_APP_ProjectName;
    },
    op() {
      return this.userInfo;
    },
    redirectUrl(value) {
      if (value === "index") {
        this.$router.replace({ path: "/Home/Index" });
      }
      if (value === "login") {
        if (this.$refs["ruleForm1"]) {
          this.$nextTick(() => {
            this.$refs["ruleForm1"].clearValidate();
          });
        }
        this.loginAndRegisterShow = true;
        this.showPrise = true;
        this.Codedisabled = true;
      }
      if (value === "register") {
        if (this.$refs["ruleForm"]) {
          this.$nextTick(() => {
            this.$refs["ruleForm"].clearValidate();
          });
        }
        this.loginAndRegisterShow = true;
        this.showPrise = false;
        // this.Codedisabled = false
      }
      if (value === "Home") {
        this.loginAndRegisterShow = true;
        this.showPrise = true;
        this.Codedisabled = true;
      }
    },
    handleLogout() {
      const that = this;
      this.$confirm({
        title: "提示",
        content: "真的要注销登录吗 ?",
        onOk() {
          TokenCache.deleteToken();
          OperatorCache.clear();
          location.reload();
          that.$router.replace({ path: "/Home/Index" });
        },
      });
    },
    showRegister(showPrise) {
      this.showPrise = showPrise;
      this.Codedisabled = true;
      this.ruleForm2.tel = "";
    },
    showLogin() {
      TokenCache.deleteToken();
      OperatorCache.clear();
      this.$router.replace({ path: "/Home/Index" });
      location.reload();
    },
    // 直接进入
    DirectLogin() {
      this.$router.replace({ path: "/Project/All" });
    },
    loginAndRegisterClose(done) {
      done();
      this.loginAndRegisterShow = false;
      this.ruleForm1 = {};
      this.ruleForm2 = {};
    },
    loginForm() {
      this.$refs.ruleForm1.validate((valid) => {
        if (!valid) {
          return;
        }
        this.loading = true;
        this.$http
          .post("/Base_Manage/Home/AppSubmitLogin", {
            userName: this.ruleForm1.Number,
            password: this.ruleForm1.Cipher,
          })
          .then(async (res) => {
            this.loading = false;
            if (res.Success) {
              TokenCache.setToken(res.Data);
              await this.$store.dispatch("getUserInfo");
              //设置Vuex登录标志为true，默认userLogin为false
              await this.$store.dispatch("setUser", true);
              this.$router.replace({ path: "/Project/All" });
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    submitForm(type) {
      // 验证码发送
      if (type === "发送验证码") {
        if (!this.checked) return this.$message.error("请勾选同意使用条款");
        this.$refs.ruleForm.validateField("tel", async (valid) => {
          if (valid) {
            return;
          }
          if (!this.unionid) {
            let result = await this.$http.post(
              "Base_Manage/Home/ValidateVIPUserPhone?phone=" +
              this.ruleForm2.tel
            );
            if (result.Success && !result.Data) {
              this.showPrise = true;
              this.Codedisabled = true;
              return this.$message.error("当前手机号已完成注册");
            }
          }

          this.dialogVisible = true;
        });
        return;
      }
      // 注册
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          this.$message.error({
            showClose: true,
            message: "请填写完成后注册",
            type: "error",
            duration: 0,
          });
          // this.$message.error("请填写完成后注册");
          return;
        }
        this.$http
          .post("/Base_Manage/Home/AddVIPUserInfoData", {
            Phone: this.ruleForm2.tel,
            Password: this.ruleForm2.checkPass,
            SmsCode: this.ruleForm2.word,
            unionid: this.unionid || "",
            openid: this.openid || "",
            Name: this.ruleForm2.tel,
            NickName: this.ruleForm2.tel,
            ...this.codeData,
          })
          .then(async (res) => {
            if (res.Success) {
              this.$message.success(res.Msg);
              this.showPrise = true;
              this.showRentPrise = false;
              TokenCache.setToken(res.Data);
              location.reload();
              this.$router.replace("/Project/All");
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    getCode() {
      console.log(11111)
      if (this.ruleForm2.smscode.toUpperCase() === this.key.toUpperCase()) {
        this.dialogVisible = false;
        this.Codedisabled = false;
        this.$http.post('/Base_Manage/Home/GetShortMessage?code=' + 'ZYT_VIPUser_Register',).then(codeData => {
          if (codeData.Success) {
            this.$http
              .post(
                "/Base_Manage/Home/GetShortMessage?Phone=" +
                this.ruleForm2.tel +
                "&TemplateCode=" + codeData.Data
              )
              .then((res) => {
                if (res.Success) {
                  this.codeData = JSON.parse(res.Data);
                  this.$message.success("验证码已经发送至您的手机,请注意查收");
                } else {
                  this.$message.error(res.Msg);
                }
              });
          } else {
            this.$message.error(codeData.Msg);
          }
        })

      } else {
        this.$message.error("请输入正确的验证码");
      }
    },
    yzmclick() {
      this.getYzm();
    },
    getYzm() {
      var str =
        "qwertyuioplkjhgfdsazxcvbnm1234567890QWERTYUIOPLKJHGFDSAZXCVBNM";
      var Yzm = "";
      for (var i = 0; i <= 3; i++) {
        var item = parseInt(Math.random() * (str.length - 1 + 0 + 1) - 0);
        Yzm += str[item];
      }
      this.key = Yzm;
    },
    strchange(val) {
      this.key = val;
    },
    smscodechange(text) {
      if (text.toUpperCase() === this.key.toUpperCase()) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    // 回调地址获取
    getRedirect_uri() {
      let protocolStr = document.location.protocol;
      return encodeURIComponent(protocolStr + "//www.zytsaas.com/Home/Index");
    },
    // 内嵌微信登陆
    showWX() {
      this.$nextTick(() => {
        this.wxVisible = true;
      });
    },
    // 微信登录
    wxLoginApi(openid, unionid) {
      console.log("wxLoginApi");
      this.$http
        .post("/Base_Manage/Base_VIPUserInfo/GetVIPUserInfoData", {
          openid,
          unionid,
        })
        .then(async (res) => {
          this.loading = false;
          if (res.Success) {
            if (!res.Data.Phone || res.Data.Phone.length !== 11 || res.Data.Phone.length == 0) {
              this.loginAndRegisterShow = true;
              this.showPrise = false;
              this.$message.error("当前微信号尚未关联手机号,请进行注册");
              return;
            }
            if (res.Data.Token) {
              TokenCache.setToken(res.Data.Token);
              await this.$store.dispatch("getUserInfo");
              //设置Vuex登录标志为true，默认userLogin为false
              await this.$store.dispatch("setUser", true);
              this.$router.replace({ path: "/Project/All" });
            } else {
              this.loginAndRegisterShow = true;
              this.showPrise = false;
              this.$message.error("当前微信号尚未关联手机号,请进行注册");
            }
          } else {
            this.loginAndRegisterShow = true;
            this.showPrise = false;
            this.$message.error("当前微信号尚未关联手机号,请进行注册");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/* 外部容器 */
.container {
  text-align: center;
  background-size: 100%;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0 auto;

  a {
    text-decoration: none;
  }

  /* 顶部导航栏 */
  .header_class {
    background: linear-gradient(130deg,
        rgba(255, 255, 255, 0.8),
        rgba(255, 0, 0, 0.1),
        rgba(255, 255, 255, 0.8),
        rgba(255, 0, 0, 0.1),
        rgba(255, 255, 255, 0.8));
    //background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(24px);
    text-align: left;
    z-index: 999;
    top: 0;
    position: sticky;
    width: 100%;
    height: 80px !important;
    line-height: 80px !important;
    margin: 0 auto;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

    .header_left {
      text-align: left;
      line-height: 80px;

      .logo {
        height: 50px;
        line-height: 50px;
        vertical-align: center;
        margin: 15px 20px;
        border-style: none;
      }

      .title {
        font-size: 26px;
        color: #cf1322;
        font-family: Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif;
        font-weight: bolder;
        vertical-align: top;
      }
    }

    .header_center {
      text-align: center;

      .router {
        padding: 0 35px;
        color: rgba(104, 104, 104);
        font-size: 18px;
      }

      .router_active {
        font-size: 20px;
        color: #ff0000;
        font-weight: bold;
      }
    }

    .header_right {
      cursor: pointer;
      text-align: right;
      line-height: 80px;

      .login {
        font-size: 16px;
        color: #666666;
        font-family: Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif;
        vertical-align: center;
        margin-right: 20px;
      }

      .login:hover {
        color: #ff0000;
      }

      .register {
        font-size: 16px;
        height: 40px;
        line-height: 40px;
        border-radius: 20px;
        color: #ffffff;
        background-color: #cf1322;
        font-family: Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif;
        font-weight: 600;
        padding: 0 20px;
        margin: 20px auto;
        display: inline-block;
      }

      .register:hover {
        background-color: #ff0000;
      }

      .loginInfo {
        height: 80px;
        line-height: 80px;
        font-size: 16px;
        color: #666666;
        font-family: Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif;
        font-weight: 600;
        vertical-align: center;
        padding: 0 20px;
        display: inline-block;

        .avatar {
          margin: 20px 10px 20px 0;
        }

        .showInfo {
          vertical-align: top;
        }
      }

      .loginInfo:hover {
        color: #ff0000;
      }
    }
  }
}

.dialogLoginShow {
  background: transparent;

  &::v-deep {
    .el-dialog {
      background: transparent;
    }

    .el-dialog__header {
      padding: 0;
      background: transparent;
    }

    .el-dialog__body {
      padding: 5px;
      background: transparent;
    }
  }
}

.loginAndRegister {
  width: 390px;
  margin: 0 auto;

  .box1,
  .box2 {
    width: 390px;
    height: 500px;
    border-radius: 20px;
    box-shadow: 11px 13px 31px 0px rgb(209 16 11 / 41%);
  }

  .box1 .el-form-item {
    margin-top: 40px;
  }

  .box1 .btn1 {
    margin-top: 55px;
    font-size: 18px;
  }

  .box1 .el-form-item .imgs1 {
    width: 37px;
    margin-top: 11px;
    margin-left: -55%;
  }

  .box1 .el-form-item .imgs2 {
    width: 37px;
    cursor: pointer;
  }

  .box1 .Back {
    margin-left: 75%;
    color: #d1100b;
    font-size: 16px;
    display: flex;
    margin-top: -14%;
    cursor: pointer;
  }

  .box1 .revise {
    margin-left: 79%;
    color: rgb(95, 170, 255);
    font-size: 16px;
    display: flex;
    margin-top: -11.5%;
    cursor: pointer;
  }
}
</style>
