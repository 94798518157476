import router from "@/router";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import TokenCache from "@/utils/cache/TokenCache";
import OperatorCache from "@/utils/cache/OperatorCache";
import defaultSettings from "@/config/defaultSettings";
import store from "@/store";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = [
  "Index",
  "XunJianGo",
  "XunJianGoIOS",
  "NoT",
  //H5登录
  "ExternalUserLogin",
  //PC登录
  "Shop_SubmitApprovalLogin",
  //RGPC登录
  "RG_SubmitApprovalLogin",
  //RGH5登录
  "RG_UserLogin",
  //文件分享
  "File_SharingInfo",
  //中转页面 跳转H5审核GO审核页面
  "TransferUrl",
]; // no redirect whitelist
const ExternalUList = [
  // 外部H5用户页面
  "ExternalUserInfo",
  "Shop_ApprovalInfoList",
  "Shop_ApprovalInfoDetail",
];
const Shop_SubmitApprovalList = [
  // 外部外审用户页面
  "Shop_SubmitApprovalList",
];
const RG_SubmitApprovalList = [
  // 审核GO用户页面
  "RG_SubmitApprovalList",
];
const RG_List = [
  // 外部H5用户页面
  "RG_UserInfo",
  "RG_ApprovalInfoList",
  "RG_ApprovalInfoDetail",
];
router.beforeEach((to, from, next) => {
  console.log(to, from, next);
  NProgress.start(); // start progress bar
  //获取用户登录成功后储存的登录标志
  // 已授权
  if (TokenCache.getToken()) {
    if (ExternalUList.includes(to.name)) {
      return next();
    }
    if (Shop_SubmitApprovalList.includes(to.name)) {
      return next();
    }
    if (RG_SubmitApprovalList.includes(to.name)) {
      return next();
    }
    if (RG_List.includes(to.name)) {
      return next();
    }

    //设置vuex登录状态为已登录
    OperatorCache.init(() => {
      if (OperatorCache.info.Id === undefined) {
        TokenCache.deleteToken();
        store.state.isLogin = false;
        // next({ path: '/Home/Index' })
        // NProgress.done()
      } else {
        store.state.isLogin = true;
      }
      if (to.path === "/") {
        next({ path: "/Home/Index" });
        NProgress.done();
      } else {
        const redirect = decodeURIComponent(from.query.redirect || to.path);
        // 桌面特殊处理
        if (to.path === defaultSettings.desktopPath || to.path === "/404") {
          next();
        } else {
          if (to.path === redirect) {
            next();
          } else {
            // 跳转到目的路由
            next({ path: redirect });
          }
        }
      }
    });
  } else {
    if (whiteList.includes(to.name)) {
      // 在免登录白名单，直接进入
      next();
    } else if (ExternalUList.includes(to.name)) {
      // 外部H5用户页面
      next({ path: "/ExternalUserLogin" });
    } else if (Shop_SubmitApprovalList.includes(to.name)) {
      // 外部PC用户页面
      // next();
      next({ path: "/Shop_SubmitApprovalLogin" });
    } else if (RG_SubmitApprovalList.includes(to.name)) {
      //  审核GOPC用户页面
      // next();
      next({ path: "/RG_SubmitApprovalLogin" });
    } else if (RG_List.includes(to.name)) {
      //  审核GOH5用户页面
      // next();
      next({ path: "/RG_UserLogin" });
    } else {
      next({ path: "/Home/Index" });
      NProgress.done(); // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
});

router.afterEach(() => {
  window.scroll(0, 0);
  NProgress.done(); // finish progress bar
});
