<template>
  <div class="tools">
    <div class="flexBox" @click="handleView('/ElevatorGO')">
      <div class="boxImg"></div>
      <div class="boxTit">算运力</div>
    </div>
    <div class="flexBox" @click="handleView('/ElevatorGO')">
      <div class="boxImg"></div>
      <div class="boxTit">查井道</div>
    </div>
    <div class="flexBox" @click="handleView('/ElevatorGO')">
      <div class="boxImg"></div>
      <div class="boxTit">查项目</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tools',
  data() {
    return {};
  },
  methods: {
    handleView(path) {
      this.$emit('select','1')
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.tools {
  padding: 12px;
  display: flex;
  flex-direction: column;
  .flexBox {
    width: 100%;
    height: 70px;
    border: 1px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 10px;
  }
}
</style>
