<template>
  <div class="MainPage">
    <div class="nav">
      <div class="logo">
        <img alt="" src="@/assets/zytlogo.png" />
      </div>
      <div style="display: flex; flex-direction: column; height: 100%">
        <ul style="flex: 1">
          <li v-for="item in routerList" :key="item.path">
            <div :class="item.path == activeName ? 'navItem navActive' : 'navItem'" @click="navClick(item)">
              <img :src="item.imgSrc" alt="" />
              <div class="navItemTitle">{{ item.title }}</div>
            </div>
          </li>
        </ul>
        <div style="
            flex: 9;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: end;
          ">
          <div style="padding-bottom: 15px">
            <div style="display: flex; flex: 2; flex-direction: column">
              <div style="margin: 15px auto; cursor: pointer" @click="urlMessage">
                <el-tooltip class="item" effect="dark" content="通知消息" placement="right" :open-delay="450">
                  <el-badge v-if="UnreadTotal > 0" :value="UnreadTotal">
                    <i class="el-icon-chat-dot-round" style="font-size: 30px;"></i>
                  </el-badge>
                  <el-badge v-else value="">
                    <i class="el-icon-chat-dot-round" style="font-size: 30px"></i>
                  </el-badge>
                </el-tooltip>
              </div>
              <div style="margin: 15px auto; cursor: pointer" @click="handleShowUserInfo">
                <el-tooltip class="item" effect="dark" :content="op().Name || op().NickName || op().Phone"
                  placement="right" :open-delay="450">
                  <el-avatar :size="40" :src="op() ? op().Icon : null" icon="el-icon-user-solid"></el-avatar>
                </el-tooltip>
              </div>
              <div style="margin: 15px auto; cursor: pointer" @click="handleExit">
                <el-tooltip class="item" effect="dark" content="退出登录" placement="right" :open-delay="450">
                  <i class="el-icon-switch-button" style="font-size: 30px;font-weight: 900;color: #D1100B;"></i>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="menuT" v-show="activeName.indexOf('/Project/') >= 0">
      <el-input placeholder="搜索" class="serinput" @keyup.enter.native="Search" v-model="inputValue">
        <div slot="prefix" class="prefix">
          <i slot="prefix" class="el-icon-search" style="" @click="Search"></i>
        </div>
      </el-input>

      <div class="title">项目</div>
      <div :class="menuKey == item.name ? 'menuItem menuActive' : 'menuItem'" v-for="item in ProjectMenu"
        :key="item.name" @click="menuChange(item)">
        {{ item.label }}
      </div>
      <div class="title">任务</div>
      <div style="" :class="menuKey == item.name ? 'menuItem menuActive' : 'menuItem'" v-for="item in TaskMenu"
        :key="item.name" @click="menuTaskChange(item)">
        {{ item.label }}
      </div>
    </div>
    <div class="pageRight">
      <div style="flex: 1; overflow-y: auto;background-color: #f7f7f7;">
        <router-view></router-view>
      </div>
    </div>
    <transition name="el-fade-in">
      <div v-show="modelShow" class="mask" @click.self="modelShow = false">
        <div class="modelShow">
          <Tools @select="toElevatorGO" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Tools from "@/views/Tools/Tools";
import Search from "@/assets/Search.png";
import ALL from "@/assets/ALL.png";
import Tool from "@/assets/Tool.png";
import Contacts from "@/assets/Contacts.png";
import TokenCache from "@/utils/cache/TokenCache";
import LouDon from "@/assets/ProjectInfo/LouDon.png";

import { mapState } from "vuex";

export default {
  name: "MainPageLayout",
  components: {
    Tools,
  },
  data() {
    return {
      inputValue: "", //项目任务搜索
      // 通知个数
      UnreadTotal: "",
      menuKey: "All",
      activeName: "/File",
      backShow: -1, // 后退按钮显示
      modelShow: false,
      APRList: [],
      routerList: [
        {
          path: "/File",
          title: "搜索",
          imgSrc: Search,
        },
        {
          path: "/Project/All",
          title: "项目",
          imgSrc: ALL,
        },
        {
          // 暂时注释  后续需要打开
          path: "/ElevatorGO",
          title: "工具",
          imgSrc: Tool,
        },
        {
          path: "/Mail",
          title: "通讯录",
          imgSrc: Contacts,
        },

      ],
      ProjectMenu: [
        { name: "All", label: "全部项目" },
        { name: "second", label: "参与项目" },
        { name: "third", label: "我的项目" },
        // { name: "collect", label: "收藏项目" },
      ],
      TaskMenu: [
        { name: "TaskAll", label: "全部任务" },
        { name: "Tasksecond", label: "参与任务" },
        { name: "Taskthird", label: "我的任务" },
      ],
    };
  },
  computed: {
    ...mapState(["searchValue"]),
  },
  async mounted() {
    this.activeName = this.$route.path;
    this.menuKey = this.$route.params.type;
    this.getMessageCount();
    this.$http
      .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
        Condition: "DicKey",
        Keyword: 'APR_HuaRunProjects',
      })
      .then(res => {
        if (res.Success) {
          this.APRList = res.Data.DictionaryDetailsList
          let index = this.APRList.findIndex(item => {
            return item.DicValue == this.op().Phone
          })
          if (index != -1) {
            this.routerList.push({
              path: "/statistics",
              title: "华润统计",
              imgSrc: LouDon,
            })
          }
        }
      })
  },
  watch: {
    $route: {
      handler(n, o) {
        this.activeName = n.path;
        this.backShow = this.routerList.findIndex(
          (item) => item.path == n.path
        );
      },
      deep: true,
      immediate: true,
    },
    searchValue(value, n) {
      this.inputValue = value;
    },
  },
  methods: {
    Search() {
      this.$store.commit("setSearchValue", this.inputValue);
    },
    // 返回上一页
    backMenu() {
      console.log("当前路由", this.$route);
      // 更新当前小红点通知
      this.getMessageCount();
      this.$router.go(-1);
    },
    // 跳转通知页面
    urlMessage() {
      console.log("跳转通知页面");
      this.$router.push({ path: "/My", query: { type: "通知" } });
    },
    // 获取通知
    getMessageCount() {
      this.$http
        .post("Base_Message/Base_InviteMessage/GetInviteMessageList", {
          PageIndex: 1,
          PageRows: 30,
        })
        .then((res) => {
          if (res.Success) {
            // this.data = res.Data;
            // this.total = res.Total;
            this.UnreadTotal = res.UnreadTotal;
          }
        });
    },
    menuChange(e) {
      this.$router.push("/Project/" + e.name);
      this.menuKey = e.name;
    },
    menuTaskChange(e) {
      this.$router.push("/Project/Task/" + e.name);
      this.menuKey = e.name;
    },
    op() {
      console.log(this.$store.state.userInfo)
      return this.$store.state.userInfo;
    },
    toElevatorGO() {
      this.modelShow = false;
    },
    navClick(item) {
      if (item.path === "/Tools") {
        this.modelShow = !this.modelShow;
      } else {
        this.modelShow = false;
        this.$router.push(item.path);
        this.activeName = item.path;
        if (item.path == "/Project/All") this.menuKey = "All";
      }
    },
    //获取用户信息
    handleShowUserInfo() {
      // 退出 做缓存清理
      this.$router.push("/My");
    },
    //退出账号
    handleExit(e) {
      this.$confirm('确认退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        TokenCache.deleteToken();
        this.$store.state.userInfo = {};
        this.$store.state.isLogin = false;
        this.$router.replace("/Home");
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
$color: #d1100b; // 主题色
$hoverBG: rgb(246, 247, 249); // 悬浮选中背景色

::v-deep .el-badge__content.is-fixed {
  top: 7px !important;
}

::v-deep .el-avatar img {
  display: block;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

.MainPage {
  height: 100%;
  display: flex;

  .pageRight {
    width: calc(100vw - 80px);
    // flex: 1;
    display: flex;
    flex-direction: column;
  }

  .nav {
    display: flex;
    text-align: center;
    flex-direction: column;
    width: 80px;
    box-shadow: 0 1px 5px 0 rgb(57 66 60 / 20%);
    z-index: 999;
    background: #fff;

    .logo {
      height: 70px;
      line-height: 70px;
      margin: 10px auto;

      img {
        padding: 8px 0 0 0;
        width: 50%;
      }
    }

    ul {
      li {
        margin-bottom: 8px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        .navActive {
          color: $color;
          background-color: $hoverBG;
        }

        .navItem {
          width: 70px;
          height: 59px;
          border-radius: 8px;
          font-size: 12px;
          color: #aaa;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          cursor: pointer;

          img {
            width: 27px;
          }
        }

        .navItemTitle {
          margin-top: 2px;
        }

        .navItem:hover {
          background: $hoverBG;
        }
      }
    }
  }

  .menuT {
    display: flex;
    flex-direction: column;
    width: 150px;

    padding: 0 10px;
    box-sizing: border-box;
    z-index: 998;
    background: #fff;
    border-right: 1px solid #efefef;

    .title {
      padding: 20px 0;
      font-weight: 600;
      font-size: 18px;
    }

    .menuItem {
      font-size: 14px;
      padding: 7px 10px;
      border-radius: 5px;
      margin-bottom: 5px;
      cursor: pointer;

      &:hover {
        background: #fae7e7;
        color: #d1100b;
        font-weight: 600;
      }
    }

    .menuActive {
      background: #fae7e7;
      color: #d1100b;
      font-weight: 600;
    }
  }

  .mask {
    width: calc(100% - 80px);
    height: 100vh;
    background: transparent;
    position: fixed;
    left: 80px;
    top: 0;
    z-index: 9999;

    .modelShow {
      position: absolute;
      width: 100px;
      height: 500px;
      border-radius: 10px;
      box-shadow: 1px 3px 5px #ccc;
      background: #fff;
      top: 70px;
      left: 10px;
      overflow: hidden;
    }
  }
}

.serinput {
  margin-top: 20px;
}

.prefix {
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}
</style>
