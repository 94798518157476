<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-08-14 13:32:34
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-08-14 13:57:10
 * @FilePath: \MashangqianWebByBank\src\components\Pagination.vue
 * @Description:分页组件
-->
<template>
  <div class="pagination-container" style="text-align: center; background: none">
    <el-pagination :background="background" :current-page.sync="currentPage" :page-size.sync="pageSize" :layout="layout"
      :page-sizes="pageSizes" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    total: {
      required: true,
      type: Number,
    },
    page: {
      type: Number,
      default: 1, //默认第一页
    },
    limit: {
      type: Number,
      default: 10, //默认每页20条
    },
    pageSizes: {
      type: Array,
      default() {
        return [20, 50, 100, 150, 200]; //默认显示可选的每页多少条数据
      },
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
    background: {
      type: Boolean,
      default: true,
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  created() {
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val); //改变的第几页的值赋值给父组件
      },
    },
    pageSize: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit("update:limit", val); //改变的当前页几条数据的值赋值给父组件
      },
    },
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("pagination", { pageIndex: 1, pageSize: val });
    },
    handleCurrentChange(val) {
      this.$emit("pagination", { pageIndex: val, pageSize: this.pageSize });
    },
  },
};
</script>

<style scoped lang="scss">
/*修改element 分页的默认选中背景*/
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: transparent;
  /*进行修改未选中背景和字体 */
  color: #d1100b;
}

/*修改element 分页的默认分页区域的背景颜色和字体颜色*/
::v-deep .el-pagination button {
  margin: 0 !important;
}

.pagination-container {
  background: #fff;
  padding: 10px 16px;
}

.pagination-container.hidden {
  display: none;
}
</style>
