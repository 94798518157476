import Vue from 'vue'
import Router from 'vue-router'
import { constantRouterMap } from '@/config/router.config'

// 防止连续点击多次路由报错
//先把VueRouter.prototype身上的push|replace方法进行保存一份
let originPush = Router.prototype.push
let originReplace = Router.prototype.replace
//重写VueRouter.prototype身上的push方法了
Router.prototype.push = function (location, resolve, reject) {
  //第一个形参：路由跳转的配置对象（query|params）
  //第二个参数：undefined|箭头函数（成功的回调）
  //第三个参数:undefined|箭头函数（失败的回调）
  if (resolve && reject) {
    //push方法传递第二个参数|第三个参数（箭头函数）
    //originPush：利用call修改上下文，变为(路由组件.$router)这个对象，第二参数：配置对象、第三、第四个参数：成功和失败回调函数
    originPush.call(this, location, resolve, reject)
  } else {
    //push方法没有产地第二个参数|第三个参数
    originPush.call(
        this,
        location,
        () => { },
        () => { }
    )
  }
}
//重写VueRouter.prototype身上的replace方法了
Router.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject)
  } else {
    originReplace.call(
        this,
        location,
        () => { },
        () => { }
    )
  }
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
