/* eslint-disable */
/**
 * element-ui组件
 * 该文件是为了按需加载，剔除掉了一些不需要的框架组件。
 * 减少了编译支持库包大小
 *
 * 当需要更多组件依赖时，在该文件加入即可
 */
import Vue from 'vue'
import {
    Container,
    Link,
    Input,
    InputNumber,
    Button,
    Switch,
    Slider,
    Radio,
    Checkbox,
    Select,
    Card,
    Form,
    Row,
    Col,
    Dialog,
    Table,
    Tabs,
    Icon,
    Badge,
    Popover,
    Dropdown,
    Avatar,
    Breadcrumb,
    Steps,
    Menu,
    Drawer,
    Tooltip,
    Alert,
    Tag,
    Divider,
    DatePicker,
    TimePicker,
    Upload,
    Rate,
    Progress,
    Skeleton,
    Popconfirm,
    Backtop,
    Result,
    Descriptions,
    Statistic,
    ColorPicker,
    PageHeader,
    Step,
    Carousel,
    Collapse,
    Timeline,
    Calendar,
    Image,
    Transfer,
    Cascader

} from 'element-ui'
import VueCropper from 'vue-cropper'

Vue.use(Container)
Vue.use(Link)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Button)
Vue.use(Switch)
Vue.use(Slider)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Card)
Vue.use(Form)
Vue.use(Row)
Vue.use(Col)
Vue.use(Dialog)
Vue.use(Table)
Vue.use(Tabs)
Vue.use(Icon)
Vue.use(Badge)
Vue.use(Popover)
Vue.use(Dropdown)
Vue.use(Avatar)
Vue.use(Breadcrumb)
Vue.use(Steps)
Vue.use(Result)
Vue.use(Menu)
Vue.use(Drawer)
Vue.use(Tooltip)
Vue.use(Alert)
Vue.use(Tag)
Vue.use(Divider)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Upload)
Vue.use(Rate)
Vue.use(Progress)
Vue.use(Skeleton)
Vue.use(Popconfirm)
Vue.use(Backtop)
Vue.use(Descriptions)
Vue.use(Statistic)
Vue.use(ColorPicker)
Vue.use(PageHeader)
Vue.use(Step)
Vue.use(Carousel)
Vue.use(Collapse)
Vue.use(Timeline)
Vue.use(Calendar)
Vue.use(Image)
Vue.use(Transfer)
Vue.use(Cascader)

Vue.use(VueCropper)
