
import BigNumber from 'bignumber.js'

export default {
  // 加
  add (a, b) {
    a = BigNumber(a)
    b = BigNumber(b)
    var c = a.plus(b).toNumber() // 结果需要用toNumber转为普通数字
    if (isNaN(c) || c === Infinity || c === -Infinity) {
      return 0
    } else {
      return c
    }
  },
  // 减
  minus (a, b) {
    a = BigNumber(a)
    b = BigNumber(b)
    var c = a.minus(b).toNumber()
    if (isNaN(c) || c === Infinity || c === -Infinity) {
      return 0
    } else {
      return c
    }
  },
  // 乘
  mut (a, b) {
    a = BigNumber(a)
    b = BigNumber(b)
    var c = a.multipliedBy(b).toNumber()
    if (isNaN(c) || c === Infinity || c === -Infinity) {
      return 0
    } else {
      return c
    }
  },
  // 除
  dev (a, b) {
    a = BigNumber(a)
    b = BigNumber(b)
    var c = a.dividedBy(b).toNumber()
    if (isNaN(c) || c === Infinity || c === -Infinity) {
      return 0
    } else {
      return c
    }
  }

}
