import { Axios } from '@/utils/plugin/axios-plugin'
import store from "@/store";
let inited = false

const OperatorCache = {
  info: {},
  inited () {
    return inited
  },
  init (callBack) {
    if (inited) {
      callBack()
    } else {
      Axios.post('/Base_Manage/Home/GetAppOperatorInfo').then(resJson => {
        if (resJson.Success && resJson.Data&& resJson.Data.Id) {
          this.info = resJson.Data
          store.state.userInfo = resJson.Data
          inited = true
          callBack()
        }else{
          this.info = {}
          store.state.userInfo = {}
          inited = false
          callBack()
        }
      })
    }
  },
  clear () {
    inited = false
    this.info = {}
  }
}

export default OperatorCache
