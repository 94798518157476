import Vue from 'vue'
import Vuex from 'vuex'
import { Axios } from "@/utils/plugin/axios-plugin";

Vue.use(Vuex)

export default new Vuex.Store({
    // 设置属性
    state: {
        patGOKey: '',
        isLogin: false,
        userInfo: {},
        // 提交后的基础信息商铺信息存储
        shopInfo:{},
        tableListData:[],
        searchValue:''
    },
    // 获取属性的状态
    getters: {
        //获取登录状态
        isLogin: state => state.isLogin,
    },
    // 设置属性状态
    mutations: {
        setPatGOKey(state, payload) {
            state.patGOKey = payload.key
        },
        setSearchValue(state, searchValue){
            state.searchValue = searchValue
        },
        setUserInfo(state, payload) {
            state.userInfo = payload.userInfo
            console.log(state.userInfo);
        },
        clearUserInfo(state) {
            state.userInfo = {}
            console.log(state.userInfo);
        },
        //保存登录状态
        userStatus(state, flag) {
            state.isLogin = flag
        },

        // 存储商铺信息
        getShopInfo(state, payload){
            state.shopInfo = payload
            localStorage.setItem('shopInfo', JSON.stringify(payload) )
        },
        removeShopInfo(state, payload){
            state.shopInfo={}
            localStorage.removeItem('shopInfo')
        },

        // 存储首页大表格列表数据
        setTableListData(state, payload){
            state.tableListData = payload
            localStorage.setItem('tableListData', JSON.stringify(payload) )
        }
    },
    // 应用mutations
    actions: {
        //获取登录状态
        setUser({ commit }, flag) {
            commit("userStatus", flag)
        },
        async getUserInfo(context) {
            await Axios.post('/Base_Manage/Home/GetAppOperatorInfo').then(resJson => {
                if (resJson.Success && resJson.Data) {
                    localStorage.setItem('userInfo', JSON.stringify(resJson.Data))
                    context.commit('setUserInfo', { userInfo: resJson.Data })
                }
            })
        }
    },
    modules: {}
})
